import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class HomeService {
    constructor(private http: HttpClient) { }
    get(): Observable<any> {

        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/dashboard`).pipe(map(user => user));
    }


    savequestion(question: string) {
        const formData: FormData = new FormData();
        formData.append('question', question);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/create_feedbackquestion`, formData).pipe(map(user => {
            return user;
        }));
    }

    updatequestion(id:any,question: string) {
        const formData: FormData = new FormData();
        formData.append('question', question);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/updatefeedback/` +id, formData).pipe(map(user => {
            return user;
        }));
    }

    getQuestion(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/website/get-feedback`).pipe(map(user => user));
    }

   

    getGrounds(vendorId: string) {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/grounds/all?vendorId=` + vendorId).pipe(map(user => user));
    }


    getUser(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/abharana/getUser`).pipe(map(user => user));
    }

    download(id): Promise<Blob> {
        const file = this.http.post<Blob>(`${environment.apiUrl}${environment.apiPrefix}/print/printOrder/` + id,
            { responseType: 'blob' as 'json' }).toPromise();
        return file;
    }


    getDashboard() {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/PrakashJewellers/dashboard `).pipe(map(user => user));
    }

    saveFaq(question: any,answer: any){
        const formData: FormData = new FormData();
        formData.append('question', question);
        formData.append('answer', answer);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/faq/addFaq`, formData).pipe(map(user => {
            return user;
        }));

    }

    updateFaq(id:any,question: any,answer: any){
        const formData: FormData = new FormData();
        formData.append('question', question);
        formData.append('answer', answer);

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/faq/updateFaq/` + id, formData).pipe(map(user => {
            return user;
        }));

    }

    deleteFaq(id:any){
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/faq/deleteFaq/` + id).pipe(map(user => {
            return user;
        }));
    }

    getFaq(search: any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/faq/getFaq?search=` + search).pipe(map(user => user));
    }

    getWhatsapp(year:any, month: any, page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/analytics/get-whatscount?year=`+ year + '&month=' + month + '&page=' + page).pipe(map(user => user));

    }


    getMostclick(search:any, page:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getmostorder?search=`+search + '&page=' + page).pipe(map(user => {
          return user;
        }));
      }
}
