<site-header></site-header>
<ng-template #customLoadingTemplate>
</ng-template>
<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<style type="text/css">
    img {
        height: auto;
        max-width: 100%;
    }

    .cell {
        display: table-cell;
    }

    .cell-fluid {
        width: 100%;
    }
</style>
<div class="page">
    <div class="page-header">
        <h1 class="page-title"> Users</h1>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href='/home'>Home</a></li>
            <li class="breadcrumb-item active"> Users</li>
        </ol>
    </div>
    <div class="page-content container-fluid">
        <div class="row user_main">
            <div class="col-md-12">
                <!-- Panel Kitchen Sink -->
                <div class="panel">
                    <!-- <header class="panel-heading">
                        <h3 class="panel-title">
                            Users
                            <div class="row custom-filters" style="display:none;margin-left: 12px;">
                                <div class="form-group col-md-4">
                                    <input type="text" class="form-control" placeholder="Registered on"
                                        autocomplete="off" id="fini" readonly>
                                </div>
                            </div>
                        </h3>
                    </header> -->

                    <div class="panel-body">
                        <div class="tb-wrapper">
                            <a href="{{apiUrl}}/api/v1/abharana/downloadExcel" download=""><button
                                    class="btn btn-primary float-right mb-3 user_tpdownload">Download</button></a>
                            <div class="table-responsive">
                                <table cellspacing="0" id="table"
                                    class="table table-hover dataTable table-striped table-bordered user_table w-full"
                                    datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                    <thead>
                                        <tr>
                                            <th width="10%">Sl No.</th>
                                            <th width="10%">Name</th>
                                            <th width="10%">Email</th>
                                            <th width="10%">Mobile No.</th>
                                            <!-- <th width="10%">Location</th> -->
                                            <th width="10%">Created On.</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="users$?.length != 0">
                                        <tr *ngFor="let user of users$; let i = index">
                                            <td>{{ perPage * (curPage - 1) + i + 1 }}</td>
                                            <td *ngIf="user.first_name;else name">{{ user.first_name}}</td>
                                            <ng-template #name>
                                                <td>-</td>
                                            </ng-template>
                                            <td *ngIf="user.email;else email">{{ user.email}} </td>
                                            <ng-template #email>
                                                <td>-</td>
                                            </ng-template>
                                            <td>{{ user.mobile_no?user.mobile_no:'' }}
                                            </td>
                                            <!-- <td><a target="_blank"
                                                (click)="placeMarker($event,user)" style="cursor:pointer;"><span><i class="fa fa-location-dot" aria-hidden="true"></i> Location</span></a></td> -->
                                            <td>{{ user.created_at }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="users$?.length == 0">
                                        <tr>
                                            <td colspan="7" class="no-data-available">No data!</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <!-- End Panel Kitchen Sink -->
            </div>
        </div>
    </div>
</div>
<jw-modal id="user_details">
    <div id="login">
        <div class="col-md-12">
            <div class="modal-header" style=" padding: 0; padding-bottom: 10px;">
                <h4 style="width: 100%" class="modal-title" id="myModalLabel">
                    User Details <a href="javascript:void(0);" style="float: right"
                        (click)="closeModal('user_details')"><i class="icon md-close   text-primary "></i></a>
                </h4>
            </div>
            <!-- Example Horizontal Form -->
            <div class="">
                <div class="example" *ngIf="user_details">
                    <div class="row">
                        <div class="col-md-4">
                            <img src="{{media_domain}}{{user_details.profile}}" class="user_img">
                        </div>
                        <div class="col-md-8">
                            <tr>
                                <td class="col-md-1">Name</td>:
                                <td class="col-md-1 details" *ngIf="user_details.name;name_user">{{ user_details.name}}
                                </td>
                                <ng-template #name_user>
                                    <td class="col-md-1">-</td>
                                </ng-template>
                            </tr>
                            <tr>
                                <td class="col-md-1"> Email</td>:
                                <td class="col-md-1 details" *ngIf="user_details.email;else email_details">
                                    {{ user_details.email}}</td>
                                <ng-template #email_details>
                                    <td class="col-md-1">-</td>
                                </ng-template>
                            </tr>
                            <tr>
                                <td class="col-md-1">Date of Birth</td>:
                                <td class="col-md-1 details" *ngIf="user_details.dob;else dob">{{ user_details.dob}}
                                </td>
                                <ng-template #dob>
                                    <td class="col-md-1">-</td>
                                </ng-template>
                            </tr>
                            <tr>
                                <td class="col-md-1">Country Code</td>:
                                <td class="col-md-1 details"
                                    *ngIf="user_details.country_code;else country_code_details">
                                    {{ user_details.country_code}}</td>
                                <ng-template #country_code_details>
                                    <td class="col-md-1">-</td>
                                </ng-template>
                            </tr>
                            <tr>
                                <td class="col-md-3">Mobile Number</td>:
                                <td class="col-md-3 details" *ngIf="user_details.mobile_no;else mobile_no_details">
                                    {{ user_details.mobile_no}}</td>
                                <ng-template #mobile_no_details>
                                    <td class="col-md-1">-</td>
                                </ng-template>
                            </tr>
                            <tr>
                                <td class="col-md-3">Sexual Orientation</td>:
                                <td class="col-md-3 details"
                                    *ngIf="user_details.sexual_orientation;else sexual_orientation">
                                    {{ user_details.sexual_orientation}}</td>
                                <ng-template #sexual_orientation>
                                    <td class="col-md-3">-</td>
                                </ng-template>
                            </tr>
                        </div>
                    </div>
                    <div class="about">
                        <h4 class="about_title">About User</h4>
                        <p class="about_details" *ngIf="user_details.about;else about_details">{{user_details.about}}
                        </p>
                        <ng-template #about_details>
                            Not Available
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>
<!-- End Page -->
<style type="text/css">
    .example-wrap {
        margin-bottom: 30px;
    }

    .disabledTable {
        pointer-events: none;
        opacity: 0.4;
    }
</style>